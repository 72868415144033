<template>
	<main>
		<div v-if="isLoading" class="isLoading pd:xl">
			<div class="preloader"></div>
		</div>

		<div class="play" v-show="!isLoading">
			<iframe
				id="play"
				:src="gameUrl"
				:style="{ 'height': gameHeight + 'px', 'width': gameWidth + 'px' }"
				allowfullscreen
				webkit-playsinline
				seamless
				frameborder="0"
				scrolling="no">
			</iframe>
		</div>
	</main>
</template>

<script>
import { debounce } from 'lodash'

export default {
	name: 'play',
	data () {
		return {
			isLoading: false,
			gameUrl: '',
			gameHeight: 'auto',
			gameWidth: 'auto',
			auid: '',
			ratioGame: 1
		}
	},
	methods: {
		setRatio (event) {
			if (event && event.data) {
				this.ratioGame = event.data
			} else {
				this.ratioGame = document.querySelector('#play').clientWidth / document.querySelector('#play').clientHeight
			}

			this.setSize()
		},
		setSize: debounce(function () {
			// reset zoom
			document.querySelector('meta[name=viewport]').setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, minimal-ui, viewport-fit=cover')

			setTimeout(_ => {
				document.querySelector('meta[name=viewport]').setAttribute('content', 'width=device-width, initial-scale=1.0, minimal-ui, viewport-fit=cover')
			}, 300)

			// hide header
			document.querySelector('header').style.setProperty('display', (Math.abs(window.orientation) === 90 && document.documentElement.clientHeight < 420) ? 'none' : '', 'important')

			// hide mobile browser view
			if (/Mobile/.test(navigator.userAgent) && !location.hash) {
				setTimeout(_ => {
					document.querySelector('body').scrollTop = 1
					window.scrollTo(0, 1)
					document.querySelector('body').scrollTop = 0
					window.scrollTo(0, 0)
				}, 300)
			}

			// this.gameHeight = ((window.innerHeight < window.outerHeight ? window.innerHeight : window.outerHeight) || document.documentElement.clientHeight)
			this.gameHeight = (window.innerHeight || document.documentElement.clientHeight)
			this.gameWidth = this.gameHeight * this.ratioGame

			if (this.gameWidth > document.documentElement.clientWidth) {
				this.gameWidth = document.documentElement.clientWidth
				this.gameHeight = this.gameWidth / this.ratioGame
			}
		}, 300),
		back () {
			if (this.routeFrom && this.routeFrom.name) {
				this.$router.back()
			} else {
				this.$router.replace('/')
			}
		},
		async loadGame () {
			this.isLoading = true

			if (this.$route.params.gid) {
				const result = await this.$store.dispatch('api/fetch', {
					method: 'POST',
					payload: [{
						game: [{ get_content: [{ args: [{ gid: this.$route.params.gid }] }] }]
					}]
				})

				const response = result.game.get_content[0][0]

				this.isLoading = false

				switch (response.code) {
					case 2001: {
						const game = response.data

						this.gameUrl = game.url
						this.auid = game.access_uid

						window.onbeforeunload = _ => {
							this.gameCheckOut()

							return true
						}

						if (document.getElementById('play')) {
							document.getElementById('play').onload = _ => {
								this.setRatio()

								window.addEventListener('message', this.setRatio, true)
							}
						}
						window.addEventListener('orientationchange', this.setSize, true)
						window.addEventListener('resize', this.setSize, true)

						break
					}

					case 'E3104': { // no permission to play (inactive / not logged in)
						this.back()
						setTimeout(_ => this.$store.dispatch('auth/prompt', { useNoticeSuspended: true }), 100)

						break
					}

					case 'E3101': { // invalid subscriber
						this.back()
						setTimeout(_ => this.$store.dispatch('auth/prompt', { useNoticeSuspended: true }), 100)

						break
					}

					case 'E3103': { // game not found
						this.back()

						break
					}
				}
			}
		},
		async gameCheckOut () {
			await this.$store.dispatch('api/fetch', {
				method: 'POST',
				payload: [{
					game: [{ set_content_access_ended: [{ args: [{ auid: this.auid }] }] }]
				}]
			})
		}
	},
	created () {
		this.$store.dispatch('ui/setMenu', false)

		this.loadGame()
	},
	async beforeRouteEnter (to, from, next) {
		next(vm => { vm.routeFrom = from })
	},
	async beforeRouteLeave (to, from, next) {
		window.onbeforeunload = null
		window.removeEventListener('message', this.setRatio, true)
		window.removeEventListener('orientationchange', this.setSize, true)
		window.removeEventListener('resize', this.setSize, true)
		document.getElementById('play').onload = null
		document.querySelector('header').style.setProperty('display', '')

		if (this.auid) {
			await this.gameCheckOut()
		}

		next()
	}
}
</script>

<style lang="scss" scoped>
main {
	background-color: $color-background-invert;
	color: $color-foreground-invert;

	.isLoading {
		display: flex;
		flex-grow: 1;

		.preloader {
			margin: auto;
		}
	}

	.play {
		position: relative;

		display: flex;
		flex-direction: column;
		flex-grow: 1;

		#play {
			display: block;
			margin: 0 auto;
			// min-width: 100%;
			// width: 100px;
			border: 0;
		}
	}
}
</style>
